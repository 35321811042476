import { LoadingController } from '@ionic/angular';


import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private Auth: AuthService, public loadingController: LoadingController, private router: Router) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.Auth.api_token;

    //Authentication by setting header with token value
    if (token) {
      request = request.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + token
        }
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json'
        }
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });





    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
          //if (event.body.message=)
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // console.error(error);
        if (
          error instanceof HttpErrorResponse &&
          error.status === 404 //|| error.status === 404
        ) {
          // Sign out
          ///this._authService.signOut();

          // Reload the app
          this.router.navigateByUrl('/signin')
        }

        return throwError(error);
      }));
  }


}
