import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private _authService: AuthService,
    private router: Router,
    private toast: ToastController
  ) {



    let token = localStorage.getItem('accessTokenBetseven')
    if (token != '' && token != null && token != undefined) {
      this._authService.api_token = token
    }
  }
  async ngOnInit() {




    let email = localStorage.getItem('email')
    let password = localStorage.getItem('password')

    try {

      let data: any = await this._authService.signIn(email, password)


      this._authService.user = data.data;
      this._authService.api_token = data.jwttoken
      this._authService.isLogged = true;
      let balance: any = await this._authService.getBalance();
      this._authService.balance = balance.balance;

      localStorage.setItem('accessTokenBetseven', data.jwttoken);
    } catch (error) {

    }

    //this.router.navigateByUrl('/tabs/sportsbook')



    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }
}
