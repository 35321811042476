import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedGuard } from './guards/logged.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'verparlay/:id',
    loadChildren: () => import('./verparlay/verparlay.module').then(m => m.VerparlayPageModule)
  },
  {
    path: 'verticketcaballos/:id',
    loadChildren: () => import('./verticketcaballo/verticketcaballo.module').then(m => m.VerticketcaballoPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then(m => m.SigninPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: 'sportsbook',
    loadChildren: () => import('./sportsbook/sportsbook.module').then(m => m.SportsbookPageModule)
  },
  {
    path: 'hipicas',
    loadChildren: () => import('./hipicas/hipicas.module').then(m => m.HipicasPageModule)
  },
  {
    path: 'jugadas',
    loadChildren: () => import('./jugadas/jugadas.module').then(m => m.JugadasPageModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilPageModule)
  },
  {
    path: 'verparlay',
    loadChildren: () => import('./verparlay/verparlay.module').then(m => m.VerparlayPageModule)
  },
  {
    path: 'deposito',
    loadChildren: () => import('./deposito/deposito.module').then(m => m.DepositoPageModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'verticketcaballo',
    loadChildren: () => import('./verticketcaballo/verticketcaballo.module').then(m => m.VerticketcaballoPageModule)
  },

  {
    path: 'retiros',
    loadChildren: () => import('./retiros/retiros.module').then(m => m.RetirosPageModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'transacciones',
    loadChildren: () => import('./transacciones/transacciones.module').then(m => m.TransaccionesPageModule),
    canActivate: [LoggedGuard]
  },
  {
    path: 'animalitos',
    loadChildren: () => import('./animalitos/animalitos.module').then(m => m.AnimalitosPageModule)
  },
  {
    path: 'casino',
    loadChildren: () => import('./casino/casino.module').then(m => m.CasinoPageModule)
  },
  {
    path: 'hipicas2',
    loadChildren: () => import('./hipicas2/hipicas2.module').then( m => m.Hipicas2PageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
